
























































































































































































































































































































































































































































































































































































































































































































































































































@import "../../assets/css/global.css";
@import "../../assets/css/antd.css";
#load-tracking-chart {
  width: 100%;
  margin: auto;
  height: 780px;
  overflow: hidden;
  /* background: #000; */
}
